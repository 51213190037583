<!--
    COUNTDOWN.HTML
    updated: 02.02.20

    DEPENDENCIES
        https://unpkg.com/vue/dist/vue.js

    NOTES: - https://codepen.io/liquidvisual/pen/ZKKQeN
           - https://github.com/mckamey/countdownjs
           - https://codepen.io/liquidvisual/pen/NeQYmv?editors=1010
-->

<template>
  <div class="porthole-countdown-parent">
    <!-- DIGITS -->
    <div class="digits">
      <span v-if="countdownValues.hours > 0">{{ countdownValues.hours }}:</span>
      <span>{{ countdownValues.minutes }}:</span>
      <span>{{ countdownValues.seconds }}</span>
      <!-- <span>:{{ countdownValues.milliseconds }}</span> -->
    </div>
  </div>
</template>

<script>
  import countdown from "countdown/countdown";
  import moment from "moment";

  export default {
    props: {
      countdownInSeconds: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        countdownDateTime: null,
        countdownInstance: null,
        countdownTimer: null,

        countdownValues: {
          years: null,
          months: null,
          days: null,
          hours: null,
          minutes: null,
          seconds: null,
          milliseconds: null,
        },
      };
    },
    watch: {
      countdownInSeconds() {
        this.startTimer();
      },
    },
    created() {
      this.startTimer();
    },
    beforeDestroy() {
      this.reset();
    },
    methods: {
      startTimer() {
        this.reset();

        // Set the future date target using moment.js
        this.countdownDateTime = new Date(
          moment()
            .add(this.countdownInSeconds, "seconds")
            .format()
        );

        // Assign recursive timer with countdown.js
        this.countdownTimer = () => {
          const counter = (this.countdownInstance = countdown(
            this.countdownDateTime,
            null,
            countdown.ALL
            // countdown.DEFAULTS
          ));

          // console.log({ counter });

          const counterSecondsRemaining = countdown(
            this.countdownDateTime,
            null,
            countdown.SECONDS
          ); // *total* in seconds

          this.countdownValues.years = ("0" + counter.years).slice(-2);
          this.countdownValues.months = ("0" + counter.months).slice(-2);
          this.countdownValues.days = ("0" + counter.days).slice(-2);
          this.countdownValues.hours = ("0" + counter.hours).slice(-2);
          this.countdownValues.minutes = ("0" + counter.minutes).slice(-2);
          this.countdownValues.seconds = ("0" + counter.seconds).slice(-2);
          // this.countdownValues.milliseconds = ("0" + counter.milliseconds).slice(-2);

          // IF PAST
          if (new Date(counter.start) < new Date(counter.end)) {
            this.reset();
            this.$emit("countdown-complete", true);
            return;
          }

          // Emit the current seconds count.
          this.$emit("countdown-seconds-remaining", counterSecondsRemaining.seconds);

          // Recurse.
          if (this.countdownTimer) requestAnimationFrame(this.countdownTimer);
        };

        // kick it off.
        requestAnimationFrame(this.countdownTimer);
      },
      reset() {
        cancelAnimationFrame(this.countdownTimer);

        // Reset all the values to ZERO (not null).
        Object.keys(this.countdownValues).forEach((index) => (this.countdownValues[index] = 0));
        this.countdownDateTime = null;
        this.countdownInstance = null;
        this.countdownTimer = null;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .porthole-countdown-parent {
    display: inline-block;
    margin-left: rem-calc(8);
    font-size: rem-calc(42);
    // line-height: 1;
    color: $primary;
    text-shadow: 0 0 10px rgba($primary, 0.3);
  }
</style>
